export default {
    getData(state) {
        return state.data;
    },
    getTotal(state) {
        return state.total;
    },

    getReviews(state) {
        return state.reviews;
    },
    getTotalReviews(state) {
        return state.totalReviews;
    },


    getFinancials(state) {
        return state.financials;
    },
    getTotalFinancials(state) {
        return state.totalFinancials;
    },



    getUserInfo: state => state.userInfo,

};
