// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeAuth} from "firebase/auth";
import axios from "axios";

import store from '@/store'
import Vue from "vue";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIRE_BASE_API_KEY,
    authDomain: process.env.VUE_APP_FIRE_BASE_DOMAIN,
    projectId: process.env.VUE_APP_FIRE_BASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIRE_BASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIRE_BASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIRE_BASE_APP_ID,
    measurementId: process.env.VUE_APP_FIRE_BASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAuth(app);

const messaging = getMessaging(app);

getToken(messaging, {vapidKey: process.env.VUE_APP_FIRE_BASE_VAPID_KEY}).then((currentToken) => {
    // console.log(currentToken);
    // console.log('====================');
    if (Notification.permission === "granted") {
        if (currentToken) {
            axios.post(process.env.VUE_APP_AXIOS_URL + '/admin/notifications/fcm/register', {
                "platform": "web",
                "token": currentToken
            })

            localStorage.setItem('fcm_token', currentToken);

        }
    } else {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                if (currentToken) {
                    axios.post(process.env.VUE_APP_AXIOS_URL + '/admin/notifications/fcm/register', {
                        "platform": "web",
                        "token": currentToken
                    })
                }
            }

            localStorage.setItem('fcm_token', currentToken);
        });
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

onMessage(messaging, function (payload) {

    if (Notification.permission === "granted") {
        if (payload.data.is_notification) {
            store.dispatch('admin/notifications/limit');
        } else {
            store.dispatch('vendor/orders/updateStatus', payload.data);
        }

    } else {
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {

                if (payload.data.is_notification) {
                    store.dispatch('admin/notifications/limit');
                } else {
                    store.dispatch('vendor/orders/updateStatus', payload.data);
                }
            }

        });
    }

})