export default {
    getSubscribe(state) {
        return state.subscribe;
    },
    getRooms(state) {
        return state.rooms;
    },

    getRoomIds(state) {
        return state.roomIds;
    },

    getRoomMessages(state) {
        return state.roomMessages;
    }
};
