export default {
    setData: (state, data) => {
        state.data = data;
    },

    resetVendors: (state) => {
        state.data.popular_vendors = [];
        state.data.total_popular_vendors = 0;
    },


};
