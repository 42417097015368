export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`logs${filters}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('unsetLogs');
                    setTimeout(()=>{
                        commit('setData', respo.data.data);
                    },0);


                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

};
