export default {
    getData(state) {
        return state.data;
    },
    getRoles(state) {
        return state.roles;
    },
    getDetail(state) {
        return state.detail;
    },
    getTotal(state) {
        return state.total;
    },
};
