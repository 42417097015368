export default {
  data: [],
  services: [],
  total: {
    totalItems: 5,
    totalPages: 5,
  },
  detail:{},

};
