export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },


  updateData: (state, data) => {
    let temp = state.data;
    state.data = temp.map((item) => item.id === data.id ? data : item)
  },

};
