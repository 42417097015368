export default {
    data: [],
    users: [],
    total: {
        totalItems: 5,
        totalPages: 5,
    },
    limit: [],

};
