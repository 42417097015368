// import Vue from 'vue'

// // Lib imports
// import axios from 'axios'

// Vue.prototype.$http = axios
// s
import store from '@/store/index';
import Vue from 'vue';
import axios from 'axios';
import router from "@/router";

Vue.prototype.$http = axios;
//  This is development mode URL
Vue.prototype.$http.defaults.baseURL = `${process.env.VUE_APP_AXIOS_URL}/admin/`;
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json;  charset=UTF-8';

Vue.prototype.$http.interceptors.request.use(request => {
    request.headers["locale"] = store.getters['getCurrentLocale'];

    // Update token axios header
    const authToken = request.headers.common.Authorization;
    if (!authToken) {
        const loginData = Vue.$cookies.get('login-data', {parseJSON: true});
        if (loginData) {
            request.headers.common.Authorization = `Bearer ${loginData.access_token}`;
        }
    }
    return request;
});

Vue.prototype.$http.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 400) {
            store.commit(
                'failMessage',
                error.response.data.code ? error.response.data.code : 'error',
            );
        } else if (error.response.status === 401) {
            // const loginData = Vue.$cookies.get('login-data', {parseJSON: true});

            //check if the current route is login or signup
            let loginRoutes = [
                'login',
            ];
            if (!loginRoutes.includes(router.currentRoute.name)){

                store.dispatch('auth/logout').then(r => {
                store.commit('failMessage', error.response.status);
                });
            }
        } else if (
            error.response.status === 404
            || error.response.status === 422
            || error.response.status === 403
            || error.response.status === 500
        ) {
            store.commit('failMessage', error.response.status);
        }
        return Promise.reject(error);
    },
);
