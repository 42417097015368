export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`financials/${data.type}${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async exportExcel({commit},data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`financials/export-excel${filters}`,{responseType: 'blob'})
            .then(respo => {
                if (respo.status === 200) {
                    // commit('setData', respo.data.data);
                    const url = window.URL.createObjectURL(new Blob([respo.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'financials.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async operations({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`financials/${data.type}/${data.id}${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async addOperation({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`financials/add-operation`,data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


};
