import i18n from "@/libs/i18n";

export default {

    data: [],
    conditions: [],
    users: [
        {
            id: 'all',
            label: i18n.t('discounts.form.all_users'),
            children: [],
        }
    ],
    products: null,
    total: {
        totalItems: 5,
        totalPages: 5,
    },

    usage: [],
    usageTotal: {
        totalItems: 5,
        totalPages: 5,
    },

    discountInfo: {
        apply_for: []
    },
    detail: {
        sizes: [],
        types: [],
        addons: [],
    },

    discountOns: [],
    discountOnTypes: [],
    applyFor: [],

};
