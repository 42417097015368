export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);
        await this._vm.$http
            .get(`orders${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
    async getServiceTypes({commit}) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-service-types`)
            .then(response => {
                if (response.status === 200) {
                    commit('setServiceTypes', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async detail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);
                    commit('setProducts', []);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async cancelReasons({commit}) {

        await this._vm.$http
            .get(`orders/cancel-reasons`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setReasons', respo.data.data);

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async drivers({commit},) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-drivers`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDrivers', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async statues({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-statues/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setStatues', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async updateStatus({commit}, data) {

        commit('loadingStart', null, {root: true});
        commit('updateData', data)

        commit('loadingFinish', null, {root: true});
    },

    async cancel({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/cancel/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async accept({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/accept/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getAddresses({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-addresses/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setAddresses', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getVendorProducts({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/vendor-products/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setProducts', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async getOrderTypes({commit},) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-order-types`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setOrderTypes', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getVehicleTypes({commit},) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/get-vehicle-types`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setVehicleTypes', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async productDetail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/product-detail/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setProductDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async updateUserAddress({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/update-address/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data)
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async updatePrices({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/update-prices/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data)
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async assignDriver({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/assign-driver`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async toggleDriver({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/toggle-driver`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async removeFromCart({commit}, data) {
        commit('removeProductFromOrder', data);
        commit('removeProductFromCart', data);
    },
    async addToCart({commit}, data) {
        commit('addProductToOrder', data.order);
        commit('addToCart', data.api);
    },

    async updateCart({commit}, data) {
        commit('updateProductOrder', data.order);
        commit('addToCart', data.api);
        commit('updateCart', data.api);
    },

    async unsetCart({commit},) {
        // commit('unsetProductOrder');
        commit('unsetCart');
    },


    async saveOrderChanges({commit, getters}, id) {
        commit('loadingStart', null, {root: true});
        let cart = getters['getCart']
        let deleted = getters['getDeleteOrders']
        await this._vm.$http
            .post(`orders/update-order/${id}`, {cart: cart, delete: deleted})
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async changeOrderStatus({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/update-order-status/${data.id}`, {status: data.status})
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async updateOrder({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`orders/update-order/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateOrderDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
};
