<script src="../../assets/svg/index.js"></script>
<template>
  <div class="section-heading">
    <div>
      <h1 class="section-heading__title text-semi-24" id="page_header">{{ title }}</h1>
      <p class="section-heading__sub-title">
        <router-link :to="{name:'dashboard'}">
          <home-icon class="dark-icon size-16"/>
        </router-link>
        <template v-for="path in paths">
          <dash-icon/>
          <router-link :key="path.link" :to="{name:path.link}" class="hover:text-secondary   max-1-lines">
            {{ path.title }}
          </router-link>
        </template>

        <dash-icon/>
        <span> {{ title }}</span>
      </p>
    </div>
    <div class="d-flex  align-items-stretch" style="gap: 10px;flex-wrap: wrap">
    <slot name="btns"/>
    <router-link
        v-if="routeName"
        :to="{ name: routeName }"
        class="section-heading__button cursor-pointer button-header-create"
    >
      <span class="mx-2">{{ btnTitle }}</span>
      <add-circle-icon class="mx-2"/>

    </router-link>
    </div>
    <div class="d-flex justify-content-center align-items-stretch" style="gap: 10px" v-if="btn2 || btn">

      <div
          v-if="btn2"
          @click="btn2Action"
          class="section-heading__button cursor-pointer warning-color"
      >
        <o-roles-icon class="mx-2"/>
        <span class="mx-2">{{ btn2Title }}</span>
        <slot name="btn"/>
      </div>
      <div
          v-if="btn"
          @click="btnAction"
          class="section-heading__button cursor-pointer button-header-create"
      >
        <span class="mx-2">{{ btnTitle }}</span>
        <add-circle-icon class="mx-2"/>
        <slot name="btn"/>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    paths: Array,

    routeName: {
      type: String,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    btn: {
      type: Boolean,
      default: null,
    },
    btn2Title: {
      type: String,
      default: null,
    },
    btn2: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    btnAction() {
      this.$emit('btnAction');
    },
    btn2Action() {
      this.$emit('btn2Action');
    },
  },
};
</script>
