export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`services-detail${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`services-detail/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateData', respo.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },



    async detail({commit}, data) {
        commit('loadingStart', null, {root: true});
      return  await this._vm.$http
            .post(`services-detail/detail/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('setDetail', respo.data.data);
                    commit('loadingFinish', null, {root: true});
                    return respo.data.data;
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`services-detail/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


};
