export default {
    subscribe: (state, data) => {
        state.subscribe = data;
    },
    setRoomIds: (state, data) => {
        state.roomIds = data;
    },
    setRooms: (state, data) => {
        state.rooms = [...data];
    },
    clearRoomMessages: (state) => {
        state.roomMessages = [];
    },

    setRoomMessages: (state, data) => {

        state.roomMessages = [...data];

        // data.map((message) => {
        //     if (state.roomMessages.every((_msg) => _msg._id != message._id)) {
        //         state.roomMessages.push(message)
        //     }
        //
        // })
    },
    pushRoomMessages: (state, data) => {

        if (state.roomMessages.every((message) => message._id !== data._id))
            state.roomMessages.push(data)

    },
};
