export default {
    getData(state) {
        return state.data;
    },
    getPermissions(state) {
        return state.permissions;
    },
    getDetail(state) {
        return state.detail;
    },
    getTotal(state) {
        return state.total;
    },
};
