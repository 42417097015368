export default {
  setFilterData: (state, data) => {
    state.filterData = data;
  },
  setVendorCategories: (state, data) => {
    state.vendorCategories = data;
  },
  setVendorsOnCategory: (state, data) => {
    state.vendorsOnCategory = data;
  },
};
