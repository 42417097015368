import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import state from './state.js'
import detail from "./detail";

export default {
    modules: {
        detail
    },
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
