import ToastificationContent from '@/components/wameed/Wameedmessages.vue';
import i18n from '@/libs/i18n';
import Vue from 'vue';
import {wameedNotify} from "wameed-ui/dist/wameed-ui.esm";
export default {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val;
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
  },
  SET_LANG(state, lang) {
    if (state.locales.includes(lang.locale)) {
      state.language = lang;
    }
  },
  setLocale(state, locale) {
    if (state.locales.includes(locale)) {
      state.currentLocale = locale;
    }
  },
  successMessage: (state, data) => {
    wameedNotify({title:  i18n.t('success_messages.' + data), type: 'success'})


    state.messages = 'success';
  },
  failMessage: (state, data) => {

    wameedNotify({title:  i18n.t('error_messages.' + data), type: 'fail'})
    state.messages = 'error';


  },
  loadingFinish: (state) => {
    state.loading = false;
  },
  loadingStart: (state) => {
    state.loading = true;
  },
};
