export default {

    userInfo:null,
    data: [],
    total: {
        totalItems: 5,
        totalPages: 5,
    },

    reviews: [],

    totalReviews: {
        totalItems: 0,
        totalPages: 0,
        rateAverage: 0,
        rates: {
            rate_avg: 3,
            rate_count: 20,
            rates: [
                {rate: 1, rate_count: 0},
                {rate: 2, rate_count: 0},
                {rate: 3, rate_count: 0},
                {rate: 4, rate_count: 0},
                {rate: 5, rate_count: 0}
            ]
        },
    },

    financials: [],
    totalFinancials: {
        totalItems: 5,
        totalPages: 5,
        total_orders: 0,
        his_profit: 0,
        our_profit: 0,
        total_sales: 0
    },

};
