// import axios from '@/plugins/axios';
import {
    addDoc,
    arrayUnion,
    collection,
    doc,
    getDoc,
    getFirestore,
    increment,
    onSnapshot,
    orderBy,
    query,
    runTransaction,
    setDoc,
    where,
} from "firebase/firestore";
import {userTypes} from "@/enums/userType.enum";

import moment from 'moment'
import {MessageTypeEnum} from "@/enums/messageType.enum";


const collectionName = process.env.VUE_APP_CONVERSATION_COLLECTION;

function getRoomInfo(members, wantedSection) {
    if (members) {
        let room = members.find((member) => {
            return member.type != userTypes.admin;
        });

        if (room) {
            if (wantedSection === 'name') {
                if (room.name) {
                    return room.name
                } else {
                    return 'unknown user'
                }
            }
            if (wantedSection === 'avatar') {
                if (room.avatar) {
                    return room.avatar
                } else {
                    return 'unknown user'
                }
            }
        }
    }
    return "";
}

function getUserInfo(members, userId, wantedSection) {
    if (members) {
        let room = members.find((member) => {
            return member.id == userId;
        });

        if (room) {
            if (wantedSection === 'name') {
                if (room.name) {
                    return room.name
                } else {
                    return 'unknown user'
                }
            }
            if (wantedSection === 'avatar') {
                if (room.avatar) {
                    return room.avatar
                } else {
                    return 'unknown user'
                }
            }
        }
    }

    return "";
}

function arabicToEnglish(s) {
    return s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
}


export default {
    async getRooms({commit}) {
        const db = getFirestore();

        const conversationsRef = query(
            collection(db, collectionName),
            where('isConversationWithAdmin', '==', true),
        );

        // establish a listener for the query using querySnapshot.
        onSnapshot(conversationsRef, querySnapshot => {
            let roomsIds = [];
            let rooms = [];

            querySnapshot.forEach(doc => {

                let docData = doc.data();
                roomsIds.push(docData.id);

                rooms.push({
                    roomId: docData.id,
                    index: new Date(arabicToEnglish(docData.lastMessageDate)),
                    roomName: getRoomInfo(docData.members, 'name'),
                    avatar: getRoomInfo(docData.members, 'avatar'),
                    unreadCount: docData.unreadMessagesMemberB,
                    startingAdminId: docData.startingAdminId,
                    closedById: docData.closedById,
                    users: docData.members ? docData.members.map((d) => {
                        return {
                            _id: d.id,
                            avatar: d.avatar,
                            username: d.name

                        };
                    }) : [],
                    lastMessage: {
                        content: docData.lastMessage,

                        timestamp: new Intl.DateTimeFormat('default', {
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                        }).format(new Date(arabicToEnglish(docData.lastMessageDate))),
                    },
                });
            })
            commit('setRoomIds', roomsIds)
            commit('setRooms', rooms)
        });

    },


    // admin start a new conversation with user without sending a message yet (only create a room) without room id
    async startConversation({commit}, data) {
        commit('loadingStart', null, {root: true});
        moment.locale('en');

        const db = await getFirestore();

        //get the current time in seconds


        //add doc with custom ref id



        const roomRef = doc(db, collectionName, data.id);
        await setDoc(roomRef, {
            id: data.id,
            members: data.members,
            membersId: data.membersId,
            lastMessage: "",
            lastMessageDate: moment().format(),
            unreadMessagesMemberA: 0,
            unreadMessagesMemberB: 0,
            isConversationWithAdmin: true,
            startingAdminId: data.admin.id,
            closedById: null,
        });

        await addDoc(collection(db, collectionName, data.id, 'messages'), {
            body: "",
            created: moment().format(),
            senderId: data.admin.id,
            type: "",
        });


        commit('loadingFinish', null, {root: true});
    },


    async sendMessage({commit}, data) {
        moment.locale('en');

        // todo:: unreadMessages

        const db = await getFirestore();
        const roomDoc = doc(db, collectionName, data.roomId);

        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(roomDoc);
            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }

            const startAdminId = sfDoc.data().startingAdminId;
            const closedById = sfDoc.data().closedById;
            const membersId = sfDoc.data().membersId;
            if (closedById) {
                commit('failMessage', 'chat_already_closed', {root: true});
                return false;
            }
            if (startAdminId == null) {

                let updateData = {
                    startingAdminId: data.user.id,
                    members: arrayUnion(data.user),
                    membersId: arrayUnion(data.user.id),
                    lastMessage: data.message.content,
                    lastMessageDate: moment().format(),
                    unreadMessagesMemberA: increment(1),
                    unreadMessagesMemberB: 0,
                }

                if (data.message.type !== MessageTypeEnum.text) {
                    updateData['lastMessage'] = data.message.type;
                }

                transaction.update(roomDoc, updateData);


                await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
                    body: data.message.content,
                    created_at: moment().format(),
                    sender_id: data.user.id,
                    type: data.message.type,
                })
                return startAdminId;
            } else {
                if (membersId.includes(data.user.id)) {
                    let updateData = {
                        unreadMessagesMemberA: increment(1),
                        unreadMessagesMemberB: 0,
                        lastMessage: data.message.content,
                        lastMessageDate: moment().format(),
                    }

                    if (data.message.type !== MessageTypeEnum.text) {
                        updateData['lastMessage'] = data.message.type;
                    }

                    transaction.update(roomDoc, updateData);

                    await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
                        body: data.message.content,
                        created_at: moment().format(),
                        sender_id: data.user.id,
                        type: data.message.type,
                    })
                    return true;
                }
                commit('failMessage', 'chat_user', {root: true});

                return false;
            }
        });


    },

    async addUserToRoom({commit}, data) {
        commit('loadingStart', null, {root: true});
        moment.locale('en');


        const db = await getFirestore();
        const roomDoc = doc(db, collectionName, data.roomId);

        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(roomDoc);
            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }

            const startAdminId = sfDoc.data().startingAdminId;
            const membersId = sfDoc.data().membersId;
            const closedById = sfDoc.data().closedById;

            if (closedById) {
                commit('failMessage', 'chat_already_closed', {root: true});
                return false;
            }
            if (!membersId.includes(data.user.id)) {
                var _data = {
                    members: arrayUnion(data.user),
                    membersId: arrayUnion(data.user.id),
                };
                if (startAdminId == null) {
                    _data['startAdminId'] = data.sender_id;
                }
                transaction.update(roomDoc, _data);

                await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
                    body: data.message.content,
                    created_at: moment().format(),
                    sender_id: data.sender_id,
                    type: data.message.type,
                })

                commit('successMessage', 'add_to_chat', {root: true});
                return startAdminId;
            }
            commit('failMessage', 'add_to_chat', {root: true});
            return false;
        });

        commit('loadingFinish', null, {root: true});

    },

    async closeRoom({commit}, data) {
        commit('loadingStart', null, {root: true});
        moment.locale('en');


        const db = await getFirestore();
        const roomDoc = doc(db, collectionName, data.roomId);

        let val = await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(roomDoc);
            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }

            const startAdminId = sfDoc.data().startingAdminId;
            const closedById = sfDoc.data().closedById;
            const membersId = sfDoc.data().membersId;
            if (closedById == null) {
                if (membersId.includes(data.sender_id)) {
                    let _data = {
                        closedById: data.sender_id,
                        closedDate: moment().format(),
                    };
                    if (startAdminId == null) {
                        _data['startAdminId'] = data.sender_id;
                    }
                    transaction.update(roomDoc, _data);

                    await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
                        body: data.message.content,
                        created_at: moment().format(),
                        sender_id: data.sender_id,
                        type: data.message.type,
                    })
                    commit('successMessage', 'close_chat', {root: true});
                    return true;
                }

                commit('failMessage', 'close_chat_not_member', {root: true});
                return startAdminId;
            }

            commit('failMessage', 'close_chat', {root: true});
            return false;
        });

        commit('loadingFinish', null, {root: true});
        return val;

    },

    async getRoomMessages({commit, getters}, roomId) {

        commit('clearRoomMessages');
        const db = await getFirestore();
        const conversations = doc(db, collectionName, roomId);
        const snapshot = await getDoc(conversations);
        const members = snapshot.data().members;

        if (getters['getSubscribe']) {
            getters['getSubscribe']();
        }

        if (snapshot.exists()) {
            const messageRef = query(
                collection(db, collectionName, roomId, 'messages'),
                orderBy('created_at', 'asc'),
            );
            // establish a listener for the query using querySnapshot.
            let subscribe = onSnapshot(messageRef, querySnapshot => {


                let messages = [];
                querySnapshot.forEach(doc => {
                    const msg = doc.data();

                    const message = {
                        _id: doc.id,
                        content: msg.body,
                        type: msg.type,
                        senderId: msg.sender_id,
                        username: getUserInfo(members, msg.sender_id, 'name'),
                        avatar: getUserInfo(members, msg.sender_id, 'avatar'),
                        timestamp: new Intl.DateTimeFormat('default', {
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                        }).format(new Date(arabicToEnglish(msg.created_at))),
                        date: new Date(arabicToEnglish(msg.created_at)).toDateString(),
                    };
                    if (msg.type === MessageTypeEnum.image) {
                        message['files'] = [
                            {
                                type: "png",
                                url: msg.body,
                                preview: msg.body,
                            },
                        ];
                        message['content'] = ''
                    }

                    // commit('pushRoomMessages', message);
                    messages.push(message);
                });

                commit('setRoomMessages', messages);
            });

            commit('subscribe', subscribe);


        }
    },
};
