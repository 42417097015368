export default {

    async uploadFiles({commit}, data) {

        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`upload-files`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((respo) => {
                commit('loadingFinish', null, {root: true});
                if (respo.status === 200) {
                    return respo.data.data;
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async getVendorCategories({commit}) {

        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`vendor-categories`)
            .then((respo) => {
                if (respo.status === 200) {
                    commit('setVendorCategories', respo.data.data);
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async getVendorsOnCategory({commit}, id) {

        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`vendors-on-category?category_id=${id}`)
            .then((respo) => {
                if (respo.status === 200) {
                    commit('setVendorsOnCategory', respo.data.data);
                }

                commit('loadingFinish', null, {root: true});
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

};
