export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`reviews${filters}`)
            .then((response) => {
                if (response.status === 200) {

                    commit('setData', response.data.data);
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`reviews/toggle`, data)
            .then(response => {
                if (response.status === 200) {

                    commit('updateData',response.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async exportExcel({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`reviews/export-excel`, {responseType: 'blob'})
            .then(respo => {
                if (respo.status === 200) {
                    // commit('setData', respo.data.data);
                    const url = window.URL.createObjectURL(new Blob([respo.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', data.type + '.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

};
