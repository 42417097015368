export default {
  getFilterData(state) {
    return state.filterData;
  },

  getVendorCategories(state) {
    return state.vendorCategories;
  },

  getVendorsOnCategory(state) {
    return state.vendorsOnCategory;
  },
};
