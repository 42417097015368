import router from "@/router";
import i18n from "@/libs/i18n";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`system-roles${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getDetail({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`system-roles/detail/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetDetail({commit}, ) {

        commit('unsetDetail');
    },


    async getPermissions({commit}) {

        await this._vm.$http
            .get(`system-roles/permissions`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setPermissions', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`system-roles/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateData', respo.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                if (error.response.status === 405) {
                    commit('failMessage', 'toggle_role', {root: true});
                }else {

                commit('failMessage', 'toggle', {root: true});
                }
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-roles/create`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `system-roles`, params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-roles/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `system-roles`, params: {lang: i18n.locale}});

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                if (error.response.status === 405) {
                    commit('failMessage', 'update_role', {root: true});
                }
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-roles/delete/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem', data.id);
                }
            })
            .catch(error => {
                if (error.response.status === 405) {
                    commit('failMessage', 'delete_role', {root: true});
                }
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
