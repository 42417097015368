export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
    setLimit: (state, data) => {
        state.limit = data.content
    },
    setUsers: (state, data) => {
        state.users = data
    }
};
