export default {

    async basic({commit}) {
        // commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`profile`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setBasicData', response.data.data);

                    // commit('loadingFinish', null, {root: true});
                }
                return response.data.data;
            })
            .catch((error) => {
                // commit('loadingFinish', null, {root: true});
                throw error;
            });

    },


    async full({commit}) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`profile/full`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setFullData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`profile/update`, data)
            .then((response) => {
                if (response.status === 200) {
                    commit('loadingFinish', null, {root: true});
                    commit('successMessage', 'contacts_changed');
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


};
