export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },

  getApplyFor(state) {
    return state.applyFor;
  },


};
