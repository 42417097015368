export default {


    async get({commit}) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`contact-settings`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`contact-settings/update`, data)
            .then((response) => {
                if (response.status === 200) {


                    commit('successMessage', 'save', {root: true});
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {

                commit('failMessage', 'save', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


};
