import workDays from './workDays';
import general from './general';
import contacts from './contacts';
import sys_users from './sys_users';
import sys_roles from './sys_roles';
import vehicles from './vehicles';
import order_types from './order_types';
import payment_methods from './payment_methods';
import services_detail from './services_detail';
import app_messages from './app_messages';
import classifications from './classifications';
import logs from './logs';
import notifications from './notifications';


export default {
    modules: {
        app_messages,
        services_detail,
        payment_methods,
        order_types,
        vehicles,
        sys_roles,
        sys_users,
        contacts,
        general,
        workDays,
        logs,
        classifications,
        notifications
    },
    namespaced: true
}
