<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="pageHeader.title"
        :paths="pageHeader.paths"
    />

    <div class="">

      <div class="detail-navigation-header mb-5">

        <div class=" links">
          <router-link class="detail-link" :to="{name:links.info}">
            {{ $t('users.detail.nav.data') }}
          </router-link>

          <router-link class="detail-link" :to="{name:links.orders}">
            {{ $t('users.detail.nav.orders') }}
          </router-link>

          <router-link v-if="showCategories" class="detail-link" :to="{name:links.categories}">
            {{ $t('users.detail.nav.categories') }}
          </router-link>

          <router-link v-if="showProducts" class="detail-link" :to="{name:links.products}">
            {{ $t('users.detail.nav.products') }}
          </router-link>

          <router-link v-if="showFinical" class="detail-link" :to="{name:links.financial}">
            {{ $t('users.detail.nav.finical') }}
          </router-link>

          <router-link v-if="showReviews" class="detail-link" :to="{name:links.reviews}">
            {{ $t('users.detail.nav.reviews') }}
          </router-link>

          <router-link v-if="showWorkDays" class="detail-link" :to="{name:links.workdays}">
            {{ $t('users.detail.nav.workdays') }}
          </router-link>

        </div>

        <div v-if="getUserInfo" class="ml-auto inactive-container">
          <p class="inactive-title">{{ $t('users.inactive.title') }}</p>
          <wameed-switch
              :checked="getUserInfo.publish ===1"
              @onChange="(e)=>{e.preventDefault();  onChange(getUserInfo)}"/>
        </div>
      </div>


    </div>

    <div class="detail-body">
      <router-view/>
    </div>


    <warning-modal
        variant="main"
        iconName="o-toggle-icon"
        :visible="showModal"
        @close="showModal = false"
        @submitAction="toggleAction"
        :title="modal.title"
        :subTitle="modal.subTitle"
        :btnTitle="modal.btn"
    />
  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import AddOperationModal from "@/views/pages/users/addOperationModal";
import WarningModal from "@/components/WarningModal.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    WarningModal,
    AddOperationModal,
    PageHeader,

  },
  data() {
    return {
      showModal: false,
      modal: {
        title: this.$i18n.t('users.inactive.title'),
        subTitle: this.$i18n.t('users.inactive.sub_title'),
        btn: this.$i18n.t('users.inactive.btn'),
      },
      toggleUser: {id: 0, publish: 0},

      type: '',
      baseUrlName: '',
      links: {
        info: '',
        orders: '',
        categories: '',
        products: '',
        financial: '',
        reviews: '',
        operations: '',
      }
    };
  },
  computed: {
    ...mapGetters({
      getUserInfo: 'admin/users/detail/getUserInfo',
    }),
    pageHeader() {
      let path_title = this.$i18n.t('users.' + this.type + '.title');
      let title = this.$i18n.t('users.' + this.type + '.info');
      return {
        title: title,
        paths: [{title: path_title, link: this.baseUrlName}]
      }
    },
    dataLink() {
      return this.baseUrlName + '-detail';
    },
    ordersLink() {
      return this.baseUrlName + '-orders';
    },

    showReviews() {
      return this.type !== 'client';
    },
    showWorkDays() {
      return this.type === 'vendor';
    },
    showProducts() {
      return this.type === 'vendor';
    },
    showCategories() {
      return this.type === 'vendor';
    },
    showFinical() {
      return this.type !== 'client';
    },
    showFinicalOptions() {
      let name = this.$route.name;
      return name === 'users-vendors-financials' || name === 'users-drivers-financials';
    },


  },
  created() {
    this.type = this.$route.meta.type;
    this.baseUrlName = 'users-' + this.type + 's';
    this.links.info = this.baseUrlName + '-detail';
    this.links.orders = this.baseUrlName + '-orders';
    this.links.categories = this.baseUrlName + '-categories';
    this.links.products = this.baseUrlName + '-products';
    this.links.reviews = this.baseUrlName + '-reviews';
    this.links.financial = this.baseUrlName + '-financials';
    this.links.workdays = this.baseUrlName + '-workdays';

    this.$store.dispatch('admin/users/detail/info', {
      id: this.$route.params.id,
      type: this.type + 's'
    }).then(() => {
      if (this.type === 'vendor') {
        let page_header = document.getElementById('page_header');
        let text = page_header.innerText;
        if (!text.includes('/')) {
          text = text + "/ " + this.getUserInfo.name;
        }
        page_header.innerText = text;
      }
    })
  },
  mounted() {

  },
  methods: {

    goToDetail() {
      this.$router.push({
        name: 'users-financial-operations',
        params: {lang: this.$i18n.locale, type: this.$route.meta.type + 's', id: this.$route.params.id},
      });
    },


    onChange(item) {
      let publish = 1;
      if (item.publish === 1) {
        this.modal = {
          title: this.$i18n.t('users.inactive.title'),
          subTitle: this.$i18n.t('users.inactive.sub_title'),
          btn: this.$i18n.t('users.inactive.btn'),
        }
        publish = 0;
      } else {
        this.modal = {
          title: this.$i18n.t('users.active.title'),
          subTitle: this.$i18n.t('users.active.sub_title'),
          btn: this.$i18n.t('users.active.btn'),
        }

      }
      this.toggleUser = {id: item.id, publish: publish};
      this.showModal = true;
    },

    toggleAction() {
      this.$store.dispatch('admin/users/toggle', this.toggleUser).then((data) => {
        if (data)
          this.$store.commit('admin/users/detail/updateUserInfo', data)
      })
      this.showModal = false;
    },
  }

};
</script>
