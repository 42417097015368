import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`discounts${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async detail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`discounts/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDetail', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async vendorProducts({commit}, id) {

        await this._vm.$http
            .get(`discounts/vendor_products/${id}`)
            .then(response => {
                if (response.status === 200) {
                    let data = [{
                        id: 'all',
                        label: i18n.t('discounts.form.all_products'),
                        children: response.data.data,
                    }];
                    commit('setProducts', data);
                }
            })
            .catch(error => {
                throw error;
            },);
    },
    async resetProducts({commit},) {

        commit('setProducts', null);

    },

    async discountInfo({commit}, id) {
        commit('loadingStart', null, {root: true});
        commit('unsetDiscountInfo');

        await this._vm.$http
            .get(`discounts/${id}/info`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDiscountInfo', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async discountUsage({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`discounts/${data.id}/usage${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDiscountUsage', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetDetail({commit}) {
        commit('unsetDetail');
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`discounts/toggle`, data)
            .then(response => {
                if (response.status === 200) {

                    commit('updateData', response.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('discounts/create', data)
            .then(response => {
                if (response.status === 200) {
                    router.push({name: 'discounts', params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`discounts/update/${data.id}`, data)
            .then(response => {
                if (response.status === 200) {
                    router.push({name: 'discounts', params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, id) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`discounts/delete/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem', id);
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async getDiscountOn({commit}) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`discounts/ons`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDiscountOns', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async getUsers({commit}) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`discounts/users`)
            .then(response => {
                if (response.status === 200) {
                    commit('setUsers', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getConditions({commit}) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`discounts/conditions`)
            .then(response => {
                if (response.status === 200) {
                    commit('setConditions', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getApplyFor({commit}) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`discounts/apply_for`)
            .then(response => {
                if (response.status === 200) {
                    commit('setApplyFor', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    }

};
