export default {
    setData: (state, data) => {
        state.data = data;
    },

    unsetVendors: (state) => {
        state.data.vendors = {
            "vendors": [],
            "total": 0
        }
    }


};
