// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

import {getAuth, signInWithCustomToken} from "firebase/auth";

export default {
    async login({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`login`, data)
            .then((response) => {
                if (response.status === 200) {

                    const date = new Date();
                    const responseData = {
                        ...response.data.data.token,
                        exp_date: date.setSeconds(
                            date.getSeconds() + response.data.data.token.expires_in
                        ),
                    };

                    this._vm.$http.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`;

                    this._vm.$cookies.set(
                        'login-data',
                        JSON.stringify(responseData),
                        60 * 60 * 24 * 30,
                        null,
                        null,
                        true
                    );

                    const auth = getAuth();
                    signInWithCustomToken(auth, response.data.data.firebase_token)
                        .catch((error) => {
                            console.log(error)
                        });

                    window.location.reload();
                    // commit('setProfileInfo', response.data.data);
                    // router.push({name: 'dashboard', params: {lang: i18n.locale}});

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async firebaseLogin({commit},) {
        await this._vm.$http
            .post(`firebase-login`)
            .then((response) => {
                if (response.status === 200) {


                    const auth = getAuth();
                    signInWithCustomToken(auth, response.data.data)
                        .catch((error) => {
                            console.log(error)
                        });

                }
            })
            .catch((error) => {

                commit('failMessage', 'firebase_fail', {root: true});
                throw error;
            });
    },

    async refreshToken({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`refresh`, null, {headers: {refresh_token: data}})
            .then((respo) => {
                if (respo.status === 200) {
                    delete this._vm.$http.defaults.headers.common['Authorization'];
                    const date = new Date();
                    const responseData = {
                        ...respo.data.data,
                        exp_date: date.setSeconds(
                            date.getSeconds() + respo.data.data.expires_in
                        ),
                    };
                    this._vm.$http.defaults.headers.common['Authorization'] = `Bearer ${responseData.token}`;

                    this._vm.$cookies.set(
                        'login-data',
                        JSON.stringify(responseData),
                        60 * 60 * 24 * 30,
                        null,
                        null,
                        true
                    );


                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    commit('failMessage', error.response.status, {root: true});
                }
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
    async checkLoggedIn({commit, dispatch}) {
        // let loginData = JSON.parse(localStorage.getItem('loginData'))\
        let loginData = this.$cookies.get('login-data', {parseJSON: true});
        if (loginData) {

            this._vm.$http.defaults.headers.common['Authorization'] = 'Bearer ' + loginData.token;

            commit('setIsLoggedIn', loginData);

            dispatch('loadUserDetail');
        }
    },

    async logout() {
        try {
            let token = localStorage.getItem('fcm_token');
            await this._vm.$http
                .post(`/notifications/fcm/unregister`, {
                    token: token
                })
                .then((respo) => {
                    if (respo.status === 200) {
                        localStorage.removeItem('fcm_token');
                    }
                });
        } catch (e) {
            console.log(e)
        }
        this._vm.$cookies.remove('login-data');


        let loginData = this._vm.$cookies.get('login-data', {parseJSON: true});
        if (!loginData) {
            this._vm.$http.defaults.headers.common['Authorization'] = undefined;

            await router.push({name: 'login', params: {lang: i18n.locale}});
        }
    },

    async forgetPassword({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`forget-password`, data)
            .then((respo) => {
                if (respo.status === 200) {
                    this._vm.$cookies.set(
                        'forgot-password-email',
                        JSON.stringify(data)
                    );
                    router.push({
                        name: 'validate-email',
                        params: {lang: i18n.locale},
                    });


                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {

                commit('loadingFinish', null, {root: true});
                Promise.reject(error);
            });
    },


    async resendCode({commit, dispatch}) {
        commit('loadingStart', null, {root: true});

        let userEmail = this._vm.$cookies.get('forgot-password-email', {
            parseJSON: true,
        });
        if (userEmail) {
            dispatch('forgetPassword', userEmail)
                .then((respo) => {
                    if (respo.status === 200) {
                        this._vm.$cookies.set(
                            'forgot-password-email',
                            JSON.stringify(userEmail),
                            60 * 10
                        );

                        commit('loadingFinish', null, {root: true});
                    }
                })
                .catch((error) => {
                    commit('loadingFinish', null, {root: true});
                    throw error;
                });
        } else {
            commit('loadingFinish', null, {root: true});

            await router.push({name: 'forgot-password', params: {lang: i18n.locale}});
        }
    },
    async confirmCode({commit}, data) {
        commit('loadingStart', null, {root: true});

        let userEmail = this._vm.$cookies.get('forgot-password-email', {
            parseJSON: true,
        });
        if (userEmail) {
            await this._vm.$http
                .post(`confirm-token`, {...data, ...userEmail})
                .then((respo) => {
                    if (respo.status === 200) {
                        commit('successMessage', 'code_confirmed', {root: true});

                        router.push({
                            name: 'reset-password',

                            params: {token: respo.data.data, lang: i18n.locale},
                        });

                        commit('loadingFinish', null, {root: true});
                    }
                })
                .catch((error) => {
                    commit('loadingFinish', null, {root: true});
                    throw error;
                });
        } else {
            commit('loadingFinish', null, {root: true});

            router.push({name: 'forgot-password', params: {lang: i18n.locale}});
        }
    },
    async resetPassword({commit}, data) {
        commit('loadingStart', null, {root: true});

        let userEmail = this._vm.$cookies.get('forgot-password-email', {
            parseJSON: true,
        });
        if (userEmail) {
            await this._vm.$http
                .post(`reset-password`, {...data, ...userEmail})
                .then((respo) => {
                    if (respo.status === 200) {
                        commit('successMessage', 'password_changed', {root: true});
                        router.push({
                            name: 'login',
                            params: {lang: i18n.locale},
                        });
                        this._vm.$cookies.remove(
                            'forgot-password-email',
                            null,
                            'dashboard.halabk.wameedprojects.com',
                            true

                            // httpOnly:true,
                            // sameSite: true
                        );
                        commit('loadingFinish', null, {root: true});
                    }
                })
                .catch((error) => {
                    commit('loadingFinish', null, {root: true});
                    throw error;
                });
        } else {
            commit('loadingFinish', null, {root: true});

            router.push({name: 'forgot-password', params: {lang: i18n.locale}});
        }
    },


    async changeUserPassword({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`change-password`, data)
            .then((respo) => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                    commit('successMessage', 'password_changed', {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
