export default {


    async get({commit}) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`general-settings`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async getVendors({commit}) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`general-settings/get-vendors`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setVendors', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async resetData({commit}) {
        commit('setData', null);
    },


    async getVendorProducts({commit}, id) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`general-settings/${id}/get-vendors-products`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setVendorProducts', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async recommendedMeal({commit}, data) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`general-settings/recommended-meal`, data)
            .then((response) => {
                if (response.status === 200) {
                    commit('setVendorProducts', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


};
