import router from "@/router";
import i18n from "@/libs/i18n";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`system-users${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getChatAdmins({commit}) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`system-users/chats`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setAllData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getDetail({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`system-users/detail/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetDetail({commit},) {

        commit('unsetDetail');
    },


    async getRoles({commit}) {

        await this._vm.$http
            .get(`system-users/roles`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setRoles', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`system-users/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('updateData', respo.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-users/create`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `system-users`, params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-users/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `system-users`, params: {lang: i18n.locale}});

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async resetPassword({commit}, id) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`system-users/reset-password/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});
                }
                return respo.data.data;
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`system-users/delete/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem', data.id);
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
