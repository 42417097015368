export default {
    data: {
        'orders':{
            "xAxis_data":[],
            "series":[]
        },
        'services':{
            "src":[],
            "series":[]
        },
        'offers':{
            "xAxis_data":[],
            "series":[]
        },
        'drivers':{
            "xAxis_data":[],
            "series":[]
        },
        'users':{
            "series":[]
        },
        'vendors':{
            "vendors":[],
            "total":0
        },
    },
};
