export default {

    async get({commit}, data = '') {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .get(`notifications/my?per_page=${data}`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async getUsers({commit}) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`notifications/get-users`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setUsers', response.data.data);
                }
            }).catch((error) => {
                throw error;
            });
        commit('loadingFinish', null, {root: true});

    },

    async limit({commit},) {
        await this._vm.$http
            .get(`notifications/my?per_page=5`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setLimit', response.data.data);
                }
            }).catch((error) => {
                throw error;
            });
    },

    async send({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('notifications/send', data)
            .then((response) => {
                if (response.status === 200) {

                    commit('successMessage', 'send_notification', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    //update
    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`notifications/update/${data.id}`, data)
            .then((response) => {
                if (response.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async markAsRead({commit}) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('notifications/mark-read')
            .then((response) => {
                if (response.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
