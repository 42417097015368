import categories from './categories';
import products from './products';
import financial from './financial';
import orders from './orders';
import workDays from './workDays';


export default {
    modules: {
        products,
        categories,
        workDays,
        orders,
        financial,
    },
    namespaced: true
}
