import Vue from "vue";
import OrderToastification from "@/components/OrderToastification.vue";
import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    setAddresses(state, data) {
        state.addresses = data;
    },
    setProducts(state, data) {
        state.products = data;
    },

    setVehicleTypes(state, data) {
        state.vehicleTypes = data;
    },

    setServiceTypes(state, data) {
        state.serviceTypes = data;
    },

    setOrderTypes(state, data) {
        state.orderTypes = data;
    },

    addProductToOrder(state, data) {
        state.detail.order = [
            ...state.detail.order,
            data
        ]
        // state.detail.order = state.detail.order.map((item) => item.order_id == data.order_id && item.product_id == data.product_id && item.time == data.time ? {
        //     ...item,
        //     ...data
        // } : item)
    },
    addToCart(state, data) {
        if (!state.cart.some((item) => item.order_id == data.order_id && item.product_id == data.product_id && item.time == data.time)) {
            state.cart.push(data)
        }

    },
    updateCart(state, data) {
        state.cart = state.cart.map((item) => item.order_id == data.order_id && item.product_id == data.product_id && item.time == data.time ? {
            ...item,
            ...data
        } : item)
    },
    updateProductOrder(state, data) {
        state.detail.order = state.detail.order.map((item) => item.id == data.id && item.product_id == data.product_id && item.time == data.time ? {
            ...item,
            ...data
        } : item)
    },
    unsetProductOrder(state) {
        state.detail.order = [];
    },
    unsetCart(state) {
        state.cart = [];
    },
    removeProductFromOrder(state, data) {
        state.deletedOrders.push(data.order_id);
        let temp = state.detail.order;
        state.detail.order = temp.filter((item) => item.id != data.order_id || item.product_id != data.product_id || item.time != data.time)
    },
    removeProductFromCart(state, data) {
        let temp = state.cart;
        state.cart = temp.filter((item) => item.id !== data.order_id && item.product_id !== data.product_id && item.time != data.time)
    },
    setProductDetail(state, data) {
        state.productDetail = data;
    },

    setDrivers(state, data) {
        state.drivers = data;
    },

    setStatues(state, data) {
        state.statues = data;
    },
    setDetail: (state, data) => {
        state.detail = data;
    },
    setReasons: (state, data) => {
        state.cancelReasons = data;
    },


    updateData: (state, data) => {
        if (data.is_order) {
            let temp = state.data;

            if (data.is_review) {
                data.review = JSON.parse(data.review)
            }
            state.data = temp.map((item) => item.id == data.id ? {
                ...item,
                ...data
            } : item)

            if (state.detail) {
                if (state.detail.id == data.id) {
                    state.detail = {
                        ...state.detail,
                        ...data
                    }
                }
            }
        } else {
            if (data.is_new_order) {
                // Can accept an Object of options
                let notificationTitle = i18n.t('notifications.new_order_title');
                let notificationBody = i18n.t('notifications.new_order_body', {'no': data.no});

                if (('change_schedule_state' in data) && data.change_schedule_state==1) {
                    notificationTitle = i18n.t('notifications.change_schedule_title');
                    notificationBody = i18n.t('notifications.change_schedule_body', {'no': data.no});
                }
                if (!state.newOrders.some((item) => item.id == data.id)) {
                    // store.commit('successMessage', 'toggle', {root: true});
                    state.newOrders.unshift(data)
                    const audio = new Audio('/notification.mp3')
                    audio.play()
                    Vue.$toast(
                        {
                            component: OrderToastification,
                            props: {
                                orderId: data.id,
                                title: notificationTitle,
                                btn: i18n.t('common.show_details'),
                                body: notificationBody
                            },
                            listeners: {
                                goToDetail: () => {
                                    router.push({
                                        name: 'order-detail',
                                        params: {lang: i18n.locale, id: data.id},
                                    });
                                }  // Optional
                            }
                        },
                        {
                            toastClassName: "new-order-notification",
                            position: "bottom-right",
                            timeout: 0,

                        },
                    );

                }
            }
            if (!state.data.some((item) => item.id == data.id)) {
                state.data.unshift(data)
            }
        }
    },

    updateOrderDetail: (state, data) => {

        if (state.detail.id == data.id) {
            state.detail = {
                ...state.detail,
                ...data,
            }
        }

    },
};
