export default {
    newOrders: [],
    data: [],
    addresses: [],
    drivers: [],
    statues: [],
    products: [],
    orderTypes: [],
    serviceTypes: [],
    vehicleTypes: [],
    cart: [],
    cancelReasons: [],
    deletedOrders:[],
    total: {
        totalItems: 5,
        totalPages: 5,
    },
    productDetail: {},

    detail: {
        driver: {
            id: 0
        },

    }

};
