export default {
    getData(state) {
        return state.data;
    },
    getDrivers(state) {
        return state.drivers;
    },
    getAddresses(state) {
        return state.addresses;
    },
    getTotal(state) {
        return state.total;
    },

    getStatues(state) {
        return state.statues;
    },

    getCancelReasons(state) {
        return state.cancelReasons;
    },


    getDetail(state) {
        return state.detail;
    },

    getProducts(state) {
        return state.products;
    },

    getServiceTypes(state) {
        return state.serviceTypes;
    },
    getOrderTypes(state) {
        return state.orderTypes;
    },

    getVehicleTypes(state) {
        return state.vehicleTypes;
    },

    getProductDetail(state) {
        return state.productDetail;
    },


    getCart(state) {
        return state.cart;
    },
    getDeleteOrders(state) {
        return state.deletedOrders;
    },


};
