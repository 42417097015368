import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`discounts/vendor${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async detail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`discounts/vendor/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDetail', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },



    async resetProducts({commit},) {

        commit('setProducts', null);

    },

    async discountInfo({commit}, id) {
        commit('loadingStart', null, {root: true});
        commit('unsetDiscountInfo');

        await this._vm.$http
            .get(`discounts/vendor/${id}/info`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDiscountInfo', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async discountUsage({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`discounts/vendor/${data.id}/usage${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDiscountUsage', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetDetail({commit}) {
        commit('unsetDetail');
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`discounts/vendor/toggle`, data)
            .then(response => {
                if (response.status === 200) {

                    commit('updateData', response.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async reject({commit}, id) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`discounts/vendor/reject/${id}`)
            .then(response => {
                if (response.status === 200) {

                    commit('updateData', response.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async approve({commit}, id) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`discounts/vendor/approve/${id}`)
            .then(response => {
                if (response.status === 200) {

                    commit('updateData', response.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    }



};
