export default {
    async unsetData({commit}) {
        commit('unsetData');
    },
    async orders({commit}, data) {
        // commit('unsetData');
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`users/${data.type}/detail/${data.id}/orders${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async reviews({commit}, data) {
        // commit('unsetData');
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`users/${data.type}/detail/${data.id}/reviews${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setReviews', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async financials({commit}, data) {
        // commit('unsetData');
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`users/${data.type}/detail/${data.id}/financials${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setFinancials', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async info({commit}, data) {
        commit('loadingStart', null, {root: true});

        return await this._vm.$http
            .get(`users/${data.type}/detail/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setUserInfo', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                    return respo.data.data;
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async unsetDetail({commit}) {
        commit('unsetDetail');
    },


};
