const MessageTypeEnum = {
    add: "add",
    close: "close",
    text: "text",
    image: "image",
    video: "video",
    audio: "audio",
    file: "file",
}

export {MessageTypeEnum}
