import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`users/vendors/${data.vendor_id}/products${filters}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('unsetProducts');
                    setTimeout(()=>{
                        commit('setData', respo.data.data);
                    },0);


                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async exportExcel({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`users/vendors/${data.vendor_id}/products/export-excel`, {responseType: 'blob'})
            .then(respo => {
                if (respo.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([respo.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'products.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async importExcel({commit,}, data) {
        commit('loadingStart', null, {root: true});


        return await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/import-excel`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});
                    return true;
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async detail({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`users/vendors/${data.vendor_id}/products/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetDetail({commit}) {
        commit('unsetDetail');
    },


    async reorder({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/reorder/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {


                    commit('updateStatusData', respo.data.data);
                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/create`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'users-vendors-products', params: {lang: i18n.locale, id: data.vendor_id}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'users-vendors-products', params: {lang: i18n.locale, id: data.vendor_id}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/vendors/${data.vendor_id}/products/delete/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem', data.id);
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async getCategories({commit}, data) {

        await this._vm.$http
            .get(`users/vendors/${data.vendor_id}/products/categories`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setCategories', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

};
