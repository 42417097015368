export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);
        await this._vm.$http
            .get(`reports${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    if(data.resetVendors) {
                        commit('unsetVendors');
                    }
                    setTimeout(()=>{
                        commit('setData', respo.data.data);
                    },0)

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetVendors({commit}) {
        commit('unsetVendors');

    },

};
